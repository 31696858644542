import React from 'react';

const MethodCard = ({ name, description, type, link }) => (
  <a className="card" href={link} target="_blank" rel="noreferrer" data-type={type}>
    <div className="body">
      <h2 className="title">{name}</h2>
      <p className="text">{description}</p>
      <p className="tag">{type}</p>
    </div>
  </a>
);

export default MethodCard;