import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import Helmet from "react-helmet";
import ReactGA from 'react-ga';
import './sass/style.sass';

//Methods
import MethodCard from './components/MethodCard';

function App() {

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [methods, setMethods] = useState([]);
  const [q, setQ] = useState("");
  const [filter, setFilter] = useState('Show All');
  const FILTER_MAP = [
    'Lenses',
    'Categorizing',
    'Mapping',
    'Meta-Framework',
    'Show All',
  ];

  const filterList = FILTER_MAP.map(name => (<FilterButton key={name} name={name} isPressed={name === filter} setFilter={setFilter}/>));

  function fuzzySearch(x,y) {
    return x.toLowerCase().includes(y.toLowerCase());
  }

  function FilterButton(props) {
    return (
      <button
        type="button"
        className="btn toggle-btn"
        aria-pressed={props.isPressed}
        onClick={() => props.setFilter(props.name)}
      >
        <span>{props.name}</span>
      </button>
    );
  }

  const searchList = methods.filter(function(method){
    if (filter === 'Show All') {
      setFilter('');
    }
    return method.fields.type.includes(filter) && ( fuzzySearch(method.fields.description, q) || fuzzySearch(method.fields.name, q) );
    }).sort(function(a,b){
      if(a.fields.name.toLowerCase() < b.fields.name.toLowerCase()) return -1;
      if(a.fields.name.toLowerCase() > b.fields.name.toLowerCase()) return 1;
      return 0;
    }).map(method => <MethodCard key={method.id} {...method.fields} /> );


  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
    fetch('https://api.airtable.com/v0/appaZDdOwbb8UJLHS/The%20List?api_key=key928jLoUK3KAkic')
      .then((resp) => resp.json())
      .then( (data) => {
         setIsLoaded(true);
         setMethods(data.records);
        },

        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      );
    }, []);

  if (error) {
        return ( <div>{error.message}</div>);
    } else if (!isLoaded) {
        return (<div>loading...</div>);
    } else {
      

      return (

        <div className="application">
          <Helmet>
            <meta charSet="utf-8" />
            <link rel="canonical" href="https://useyourmethods.com" />
          </Helmet>
          <main>
            <header>
            <div className="col-left">
              <h1>Use Your Methods</h1>
              <p>Use Your Methods is a collection of rated and reviewed design methods from across the design &amp; tech community.</p>
              <p>These have been grouped into 4 types. <strong className="blue">Lenses</strong> are used to frame thinking. <strong className="orange">Categorizing frameworks</strong> are used to synthesize and affinitize. <strong className="green">Maps</strong> are used to visually sort information. Lastly, <strong className="meta">meta-frameworks</strong> are frameworks that consist of other frameworks.</p>
              </div>

              <div className="col-right">
                <div className="search">
                  <label htmlFor="search-form">
                    <h2>Search by name or description:</h2>
                    <input type="search" name="search-form" id="search-form" className="search-input" placeholder="Search for a method" value={q} onChange={(e) => setQ(e.target.value)}/>
                  </label>
                </div>

                <div className="filters">
                  <h2>Filter methods by type:</h2>
                  {filterList}
                </div>
              </div>
            </header>

            <div className="fade"></div>

            <div className="deck">
              <div className="card-row">
                {searchList}
              </div>
            </div>

            <footer>
              <p>Made with ♥ by <a href="mailto:corey.roth@postlight.com">corey.roth@postlight.com</a></p>
            </footer>
          </main>
        </div>
      );
  }
}

export default App;

ReactDOM.render(<App />, document.getElementById("root"));
